@font-face {
    font-family: 'Barlow';
    src: url('../Fonts/Barlow/Barlow-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Universal Styles for Responsive Layout */
.frontpage {
    height: 91vh; /* Use percentage for adaptive height */
    width: 100%; /* Full width */
    padding: 0; /* Use viewport-based units for padding */
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    flex-direction: row; /* Default to horizontal layout */
}

/* Text Container */
.text-container {
    flex: 1;
    text-align: center;
    margin: 2vh 2vw; /* Margin based on viewport units */
}

/* Responsive Text Styling */
.text-container h1 {
    font-family: 'Barlow', sans-serif;
    font-size: 70px;
    margin-bottom: 1rem;
    margin-top: 4rem;
}

.text-container p {
    font-family: 'Barlow', sans-serif;
    font-size: 28px;
    color: #666;
    margin-top: 2rem;
    font-weight: lighter;
}

/* Download Buttons */
.download-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem; /* Use gap for spacing between buttons */
    margin: 1rem 0;
}

.download-button {
    max-width: 8rem; /* Use relative units */
    width: 20vw; /* Adjust based on viewport width */
    height: auto;
}

/* Video Container */
.video-container {
    flex: 1;
    width: 90%; /* Dynamic width */
    height: 50vh; /* Use viewport-based height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 2vh; /* Responsive spacing */
    order: 1;
    mix-blend-mode: darken;
}

/* Video Element */
.video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    transition: none;
    outline: none;
    clip-path: fill-box;
}

/* Avoid borders or outline for focused elements */
.video-container:focus,
.video-container:active,
.video-container video:focus,
.video-container video:active {
    border: none;
    outline: none;
}

/* Responsive Layout for Smaller Screens */
@media screen and (max-width: 600px) {
    .frontpage {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        margin-top: 2rem; /* Allow height to adjust based on content */
    }

    .video-container {
        order: -1;
        margin-bottom: 1rem;
        width: 100%; /* Ensure the container spans the full width */
        max-width: 100vw; /* Prevent overflow beyond the viewport width */
        height: auto; /* Allow the height to adjust based on video aspect ratio */
        padding: 0;
        overflow: hidden; /* Prevent overflow */
    }

    .video-container video {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
        object-fit: contain; /* Ensure the entire video is visible */
        border: none;
        transition: none;
        outline: none;
        overflow: hidden;
    }

    .text-container {
        order: 1;
        width: 90%;
        margin: 1rem 0;
        text-align: center;
    }

    .text-container h1 {
        font-size: clamp(1.5rem, 5vw, 3rem);
    }

    .text-container p {
        font-size: clamp(1rem, 2.5vw, 1.5rem);
    }

    .download-buttons {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        gap: 0.5rem;
        margin-top: 0;
        margin-bottom: 0;
    }

    .download-button {
        max-width: 10rem;
        width: 25vw;
        height: auto;
        margin: 0.2rem;
    }
}

@media (max-width: 480px) {
    .download-button {
        padding: 10px 20px; /* Adjust padding for mobile */
        font-size: 14px; /* Slightly smaller font for mobile */
    }

    .button-logo {
        width: 20px; /* Smaller logo for mobile */
        height: 20px; /* Smaller logo for mobile */
    }
}

.download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00213B;
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    max-width: 320px;
    width: 600px;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 50px;
}

.download-button:hover {
    background-color: #004D73; /* Slightly darker green on hover */
    transform: translateY(-2px); /* Lift effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
}

.download-button:active {
    background-color: #00324E; /* Darker green on click */
    transform: translateY(1px); /* Slightly pressed effect */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Reduced shadow on click */
}

.button-logo {
    width: 60px; /* Logo size */
    height: 24px; /* Logo size */
    margin-right: 1px; /* Space between logo and text */
    margin-left: 0px;
    transition: transform 0.3s ease; /* Smooth logo hover effect */
}
.button-text{
    transition: transform 0.3s ease;
}
.download-button:hover .button-logo {
    transform: rotate(20deg); /* Slight logo rotation on hover */
}
