@font-face {
    font-family: 'Barlow';
    src: url('../Fonts/Barlow/Barlow-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.white-box {
    background-color: white;
    border-radius: 20px;
    padding: 2rem 3rem;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
    width: 60%;
    height: 65%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideDown 2.2s ease forwards; /* Apply the slide-down animation */
    transform: translateY(-100%); /* Initial position */
    opacity: 0; /* Initial opacity */
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Dark overlay background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content Box */
.modal-content {
    font-family: 'Barlow', sans-serif;
    width: 100%;
    height: 28rem;
    background: #09213A; /* White background for the modal */
    border-radius: 20px; /* Rounded corners */
    padding: 2rem; /* Add padding inside the modal */
    text-align: center; /* Center text inside the modal */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

/* Header Styling */
.modal-content h2 {
    font-family: 'Barlow', sans-serif;
    font-weight: bold; /* Bold header */
    font-size: 1rem; /* Larger font size for header */
    margin-bottom: 2.5rem; /* Space below the header */
    color: white; /* White text color */
}

/* Paragraph Styling */
.modal-content p {
    font-family: 'Barlow', sans-serif; /* Use the same font family */
    font-size: 0.9rem; /* Slightly smaller font size for the paragraph */
    color: white; /* Dark grey text color */
    padding-top: 3rem;
    padding-bottom: 2rem;
    line-height: 1.5; /* Set a comfortable line height */
}

.modal-content ul {
    text-align: left;
}

.bullet-points {
    margin: 0;
    padding-left: 2rem;
}

.bullet-points li::marker {
    font-size: 1.1rem; /* Increase bullet size */
}

.modal-content li {
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;         /* Set font size for the list */
    line-height: 1.5;          /* Increase line height */
    color: white;               /* Set color for list items */
    margin-bottom: 0.5rem;     /* Space between bullet points */
}

/* Close Button Styling */
.silver-close-button {
    background-color: white;
    color: #00213B;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


@media screen and (max-width: 600px) {
    .white-box {
        width: 90%; /* Adjust width to fit smaller screens */
        height: auto; /* Adjust height dynamically */
        padding: 1rem 1.5rem; /* Reduce padding for smaller devices */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slightly lighter shadow for mobile */
    }

    .modal-content {
        width: 90%; /* Adjust modal width to 90% on mobile */
        height: 30rem; /* Let height adjust automatically */
        padding: 1.5rem; /* Reduce padding for mobile screens */
    }

    .modal-content h2 {
        font-size: 1.3rem;
        margin-bottom: 0.3rem/* Slightly larger text for mobile */
    }

    .modal-content p {
        font-size: 0.85rem; /* Smaller font size for mobile readability */
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        line-height: 1rem; /* Set a comfortable line height *//* Adjust padding to fit mobile screen */
    }

    .modal-content li {
        margin-bottom: 0;
        font-size: 0.65rem; /* Adjust font size for bullet points */
    }

    .silver-close-button {
        font-size: 0.9rem; /* Reduce font size for mobile button */
        padding: 0.6rem 1.2rem;
    }
}
