@font-face {
    font-family: 'Barlow';
    src: url('../Fonts/Barlow/Barlow-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.partners-page {
    min-height: 91vh; /* Use min-height instead of height */
    margin: 0;
    padding: 0; /* Remove any default paddings */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to the top */
    align-items: center;
    background-color: #09213A;
    box-sizing: border-box;
}

/* Remove the extra padding if wrapped inside a section */
section.partners-page {
    padding-top: 0;
}

.partners-page h1 {
    font-family: 'Barlow', sans-serif;
    color: white;
    font-size: 56px;
    margin: 1rem 0; /* Add some spacing to the top */
    padding: 0; /* Remove unnecessary padding */
    text-align: center; /* Center the text horizontally */
}

.partners-page h2 {
    font-family: 'Barlow', sans-serif;
    color: white;
    font-size: 40px;
    margin: 1rem 0; /* Add some spacing to the top */
    padding: 0; /* Remove unnecessary padding */
    text-align: center; /* Center the text horizontally */
}

.partners-page p {
    font-family: 'Barlow', sans-serif;
    color: white;
    margin: 0;
    text-align: center;
    width: 77%;
    font-size: 20px;
    padding-top: 3rem; /* Fine-tune top spacing */
    padding-bottom: 7rem; /* Fine-tune bottom spacing */
}

.partners-image-section {
    width: 100%;                 /* Full width for the container */
    display: flex;               /* Use flexbox to control layout */
    justify-content: center;     /* Center images horizontally */
    align-items: center;         /* Center images vertically */
    gap: 2rem;                   /* Space between the boxes */
    background-color: #09213A;
    margin-top: 2rem;
}

.partners-image, .partners-image2 {
    width: 12rem;                /* Set fixed width for all images */
    height: 10rem;               /* Set fixed height for the boxes */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #09213A;     /* Background to make the box visible */
    padding: 1rem;               /* Padding for space inside the box */
    box-sizing: border-box;      /* Ensure padding is included in the size */
    border-radius: 8px;          /* Optional: rounded corners for the boxes */
}

.partners-image4 {
    width: 20rem;                /* Set fixed width for all images */
    height: 10rem;               /* Set fixed height for the boxes */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #09213A;     /* Background to make the box visible */
    padding: 1rem;               /* Padding for space inside the box */
    box-sizing: border-box;      /* Ensure padding is included in the size */
    border-radius: 8px;          /* Optional: rounded corners for the boxes */
}

.partners-image3 {
    width: 15rem;                /* Set fixed width for all images */
    height: 8rem;               /* Set fixed height for the boxes */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #09213A;     /* Background to make the box visible */
    padding: 1rem;               /* Padding for space inside the box */
    box-sizing: border-box;      /* Ensure padding is included in the size */
    border-radius: 8px;
}


/* Responsive Adjustments */
@media screen and (max-width: 600px) {
    .partners-page h1 {
        margin-top: 2rem;
        font-size: 32px; /* Reduce font size for smaller screens */
    }

    .partners-page h2 {
        font-size: 19px;
        max-width: 100%/* Reduce font size for smaller screens */
    }

    .partners-page p {
        font-size: 16px; /* Reduce paragraph font size */
        padding-top: 4rem;
        width: 90%;
        padding-bottom: 4rem;
    }

    .partners-image-section {
        gap: 0; /* Reduce space between boxes */
    }

    .partners-image, .partners-image2 {
        width: 5rem; /* Reduce width of image boxes */
        height: 5rem; /* Reduce height of image boxes */
    }

    .partners-image3 {
        width: 8rem; /* Adjust width for partners-image3 */
        height: 5rem; /* Adjust height for partners-image3 */
    }

    .partners-image4 {
        width: 8rem; /* Reduce width of image boxes */
        height: 5rem; /* Reduce height of image boxes */
    }
}
