@font-face {
    font-family: 'Barlow';
    src: url('../Fonts/Barlow/Barlow-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Universal Header Styles */
.header {
    font-family: 'Barlow', sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #00213B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    height: 10vh; /* Use viewport-based height */
    padding: 0 2vw; /* Adjust padding for consistent spacing */
    box-sizing: border-box;
}

/* Logo Container */
.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 5vh; /* Relative height for proportional scaling */
    max-height: 30px; /* Cap the logo height */
}

/* Navbar Container */
.navbar {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 4rem; /* Use viewport units for consistent spacing */
    justify-content: space-between;
}


.navbar a {
    padding: 0.75rem 1.5rem;
    width: 8.5rem;
    text-decoration: none;
    border-radius: 40px; /* Box rounding */
    font-weight: bold;
    transition: all 0.3s ease; /* Smooth transition */
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1); /* Soft shadow for 3D effect */
}

/* Hover Effect */
.navbar a:hover {
    background-color: white;
    color: #09213A;
    transform: translateY(-3px); /* Lift the button when hovered */
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.4);
}

/* Active State */
.navbar a.active {
    background-color: white;
    color: #09213A;
}

.navbar .nav-item4 {
    padding-left: 1.2rem;
}

.navbar .nav-item5 {
    white-space: nowrap;
}

/* Languages Container */
.languages {
    display: flex;
    align-items: center;
    gap: 1vw; /* Adjust gap between items */
    position: relative; /* Ensure dropdown is positioned correctly */
    margin-right: 1vw; /* Space it slightly away from navbar items */
}

/* Language Flags */
.selected-flag,
.language-selector img {
    width: 45px; /* Default width for flags */
    height: 45px; /* Default height */
    cursor: pointer;
    border-radius: 5px; /* Optional: Rounded corners */
    display: inline-block; /* Ensure flags remain inline */
    margin: 0; /* Remove extra margins */
}

/* Language Selector Dropdown */
.language-selector {
    display: none; /* Hidden by default */
    position: absolute; /* Position relative to the flag */
    top: calc(100% + 5px); /* Add spacing below the main flag */
    left: 50%; /* Center below the parent flag */
    transform: translateX(-50%); /* Center alignment */
    flex-direction: column; /* Stack flags vertically */
    background: #00213B;
    padding: 0.5rem;
    border-radius: 5px;
    z-index: 101; /* Ensure dropdown appears above other elements */
    gap: 0.3rem; /* Adjust gap between flags */
    opacity: 0; /* Make invisible */
    pointer-events: none; /* Prevent interaction when invisible */
    transition: opacity 0.3s ease; /* Smooth transition */
}

/* Show dropdown when active */
.language-selector,
.languages .language-selector.active {
    display: flex; /* Ensure dropdown is rendered */
    opacity: 1; /* Fade in effect */
    pointer-events: auto; /* Enable interaction */
}

/* Fix Language Selector in Mobile View */
@media (max-width: 768px) {
    .language-selector {
        top: -0.5rem;
        left: 6rem;
        transform: none; /* Remove transform for mobile */
        flex-direction: row; /* Display flags side by side in mobile */
        justify-content: center; /* Center align in mobile view */
        opacity: 1; /* Make visible in mobile */
        display: flex; /* Ensure it's displayed */
        pointer-events: auto; /* Enable interaction */
        gap: 1rem; /* Add spacing between flags */
    }

    .languages {
        width: 100%; /* Ensure full width in mobile */
        justify-content: center; /* Center the language container */
        margin-top: 2rem; /* Add spacing above flags */
    }

    .language-selector img,
    .selected-flag {
        width: 40px; /* Larger flag size for mobile view */
        height: 25px; /* Adjust height proportionally */
    }
}

/* Universal Menu Icon Styles */
.menu-icon {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}

/* Hamburger Bar Styles */
.menu-icon div {
    width: 30px; /* Width of each bar */
    height: 4px; /* Height of each bar */
    background-color: white;
    margin: 5px 0;
    border-radius: 5px;
    transition: all 0.3s ease;
}

/* Menu Icon Open State */
.menu-icon.open div:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
}

.menu-icon.open div:nth-child(2) {
    opacity: 0;
}

.menu-icon.open div:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
}

/* Navbar for Small Screens */
@media (max-width: 600px) {
    .navbar {
        position: fixed; /* Fixed positioning to cover the screen */
        top: 0;
        background-color: #00213B;
        margin-right: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column; /* Stack items */
        align-items: center;
        justify-content: center;
        gap: 2rem; /* Adjust gap for centered items */
        padding: 1rem 0;
        font-size: 1.2rem;
        transition: right 0.5s ease, opacity 0.5s ease;
        z-index: 100;
        opacity: 0;
        transform: scale(0.8);
        visibility: hidden;
    }

    .navbar a {
        text-align: center;
        height: 3rem;
    }

    .navbar .nav-item4 {
        padding-right: 0;
        display: flex;
        justify-content: flex-start;
        padding-left: 1.3rem;
    }

    .navbar.active {
        right: 0;
        opacity: 1;
        transform: scale(1);
        visibility: visible;
    }

    .menu-icon {
        display: block; /* Show menu icon on mobile */
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 102;
    }

    .logo-container {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 102;
    }

    .no-scroll {
        overflow: hidden;
        height: 100%;
    }
}
