/* Overall page styling */
.delete-account {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Arial', sans-serif;
    color: #fff;
}

/* Form container */
.form-container {
    background: #ffffff;
    padding: 30px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
    max-width: 400px;
}

/* Title and subtitle */
h1 {
    color: #333;
    margin-bottom: 10px;
}

.subtitle {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

/* Input field */
.input-field {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

/* Button */
.submit-button {
    background: #00213B;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
    width: 100%;
}

.submit-button:hover {
    background: #004D73;
}

/* Message styles */
.message {
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
}

.message.success {
    color: #4caf50;
}

.message.error {
    color: #e74c3c;
}
