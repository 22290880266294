@font-face {
    font-family: 'Barlow';
    src: url('../Fonts/Barlow/Barlow-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.subscription-page {
    height: 91vh; /* Full height of the screen */
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack header, content, and read-more vertically */
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    background-color: #09213A; /* Background color */
}

.subscription-page h1 {
    color: white;
    font-family: 'Barlow', sans-serif;
    font-size: 58px;
    padding-top: 2rem;
    margin-bottom: 3rem; /* Spacing below the header */
    padding-right: 21rem;
    text-align: center; /* Center the header */
}

/* Container for text and image side-by-side */
.subscription-content {
    display: flex;
    justify-content: space-between;
    width: 90%;
    box-sizing: border-box;
}

/* Text section on the left */
.subscription-text-section {
    color: white;
    font-family: 'Barlow', sans-serif;
    padding: 0 2rem;
    width: 100%;
}

.subscription-container {
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.subscription-text-section p {
    color: white;
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    width: 80%;
    text-align: left;
    line-height: 2rem;
}

.subscription-description {
    text-align: center;
    color: white;
    margin-top: 5rem; /* Space above the text */
    font-family: 'Barlow', sans-serif;
}


.subscription-image-section {
    align-items: center;
    padding-top: 9.5rem;

}

.subscription-image {
    width: 70%;
    cursor: pointer;
    border-radius: 2rem;
}

.subscription-image2 {
    width: 67%;
    cursor: pointer;
    border-radius: 2rem;
    padding-top: 0.5rem;
}


.read-more-overlay {
    transition: opacity 0.3s ease; /* Smooth transition */
    cursor: pointer; /* Show pointer cursor */
    padding-bottom: 1rem;
}

.read-more-overlay:hover {
    opacity: 1; /* Show the overlay when hovering */
}



.read-more-text {
    color: white;
    font-size: 20px;
    font-weight: bold;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%); /* Start above the view */
        opacity: 0; /* Start invisible */
    }
    100% {
        transform: translateY(0); /* Final position */
        opacity: 1; /* Fully visible */
    }
}

/* Update your modal content styling */
.modal-content, .silver-modal-content {
    animation: slideDown 1.5s ease forwards; /* Apply the slide-down animation */
    transform: translateY(-100%); /* Initial position */
    opacity: 0; /* Initial opacity */
}

/* Control the modal visibility when it's active */
.modal-overlay.show, .silver-modal-overlay.show {
    display: flex;
}

/* For hidden state */
.modal-content.hidden, .silver-modal-content.hidden {
    display: none;
}



/* Mobile styling */
@media screen and (max-width: 600px) {
    .subscription-page h1 {
        font-size: 26px; /* Reduce font size for smaller screens */
        padding-right: 0; /* Remove extra padding on the right */
        margin-bottom: 2rem; /* Adjust spacing below the header */
        text-align: left;
    }

    .subscription-content {
        flex-direction: column; /* Stack the content vertically */
        align-items: center; /* Center items */
        width: 100%; /* Full width for mobile */
    }

    .subscription-text-section p {
        font-size: 11px; /* Smaller font for better readability on mobile */
        width: 100%;
        line-height: 1.2;
    }

    .subscription-image {
        width: 100%; /* Make the image take full width on mobile */
        margin-top: 0.5rem; /* Add spacing above the image */
    }

    .subscription-description {
        font-size: 16px; /* Smaller font size for the description */
        margin-top: 2.5rem; /* Adjust margin for smaller screens */
    }

    .read-more-text {
        font-size: 18px; /* Adjust the "read more" text size */
    }

    .subscription-image-section {
        align-items: center;
        padding-top: 1rem;

    }

    .subscription-image {
        width: 60%;
        cursor: pointer;
        border-radius: 1rem;
    }

    .subscription-image2 {
        width: 57.7%;
        cursor: pointer;
        border-radius: 1rem;
        padding-top: 0.8rem;
    }

    .read-more-overlay {
        padding-bottom: 0.5rem; /* Adjust padding for smaller screens */
    }

    .read-more-text {
        font-size: 16px; /* Smaller font for better readability on mobile */
        font-weight: bold;
    }

    .modal-content, .silver-modal-content {
        animation: slideDown 1s ease forwards; /* Slightly faster animation for mobile */
    }

    .modal-overlay.show, .silver-modal-overlay.show {
        display: flex;
        align-items: center; /* Ensure content is centered vertically on smaller screens */
        justify-content: center; /* Center the modal horizontally */
    }

    .modal-content, .silver-modal-content {
        width: 90%; /* Take up most of the screen on mobile */
        max-width: 400px; /* Limit the width for smaller devices */
        padding: 1rem; /* Add padding inside the modal */
    }
}
