/* Popup styles */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* High z-index to make sure it appears above everything else */
}

.popup-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-content p {
    font-size: 18px;
    margin-bottom: 1.5rem;
}

.popup-close {
    background-color: #09213A;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.popup-close:hover {
    background-color: #05416B; /* Darken button on hover */
}

/* Contact Page Styles */
.contact-page {
    min-height: 45rem;
    max-width: 100%;
}

.contact-page h2 {
    font-family: 'Barlow', sans-serif;
    font-size: 54px;
    padding-bottom: 3rem;
    padding-left: 6rem;
    padding-right: 15rem;
}

.contact-form {
    display: flex;
    flex-direction: column;
    padding-left: 6rem;
    padding-right: 15rem;
}

.form-row {
    display: flex;
    justify-content: space-between;
}

.form-group {
    flex: 1;
    margin-bottom: 1.5rem; /* Increase the spacing between form groups */
}

.form-group label {
    display: block;
    margin-bottom: 0.75rem;
    font-size: 20px;
}

.form-group input,
.form-group textarea {
    width: 50%;
    padding: 1rem;
    font-size: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group-email input {
    width: 90%;
}

.form-group-message textarea {
    width: 100%;
}

.form-group textarea {
    height: 200px;
    resize: vertical;
}

.send-button {
    font-family: 'Barlow', sans-serif;
    align-self: flex-end;
    padding: 0.75rem 1.5rem;
    font-size: 20px;
    background-color: #09213A;
    color: white;
    border: none;
    border-radius: 30px;
    width: 15%;
}

.send-button:hover {
    cursor: pointer;
    background-color: #05416B;

}

/* Footer Styles */
.footercontainer {
    font-family: 'Barlow', sans-serif;
    padding-top: 2rem;
    width: 100%; /* Full width of the viewport */
    position: relative; /* Ensure it's in the normal document flow */
}

.footer {
    height: 50px; /* Set the height of the footer */
    background-color: #09213A; /* Background color */
    color: white;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-link {
    color: white; /* Match the footer text color */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Optional: make it bold for visibility */
}

.privacy-link:hover {
    text-decoration: underline; /* Underline on hover to indicate it's clickable */
}

/* Style the <hr> */
.footer-line {
    height: 1rem;
    margin: 0 8px;           /* Add space above and below the line */
}


/* Message Status Styles */
.message-status {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
}

.message-status.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.message-status.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.deleteaccount {
    color: white; /* Match the footer text color */
    text-decoration: none; /* Remove underline */
}

.deleteaccount:hover {
    text-decoration: underline; /* Underline on hover to indicate it's clickable */
}

/* Responsive Styles */
@media screen and (max-width: 600px) {
    .contact-page {
        min-height: auto;
        width: 100%;
    }

    .contact-page h2 {
        font-size: 2rem; /* Smaller font for smaller screens */
        padding: 0 0 1rem 1rem; /* Simplified padding */
    }

    .contact-form {
        padding: 0;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .form-row {
        flex-direction: column; /* Stack items vertically on smaller screens */
        gap: 1rem;
    }

    .form-group {
        width: 100%; /* Full width for small screens */
        margin-bottom: 1rem;
    }

    .form-group label {
        font-size: 1em;
    }

    .form-group input,
    .form-group textarea {
        width: 100%; /* Full width for inputs and textarea */
        padding: 0.75rem;
        font-size: 0.875rem; /* Slightly smaller font size */
    }

    .send-button {
        width: 100%; /* Full width for better usability on mobile */
        margin-top: 1rem; /* Adds margin to separate from inputs */
    }
    .footercontainer {
        font-family: 'Barlow', sans-serif;
        padding-top: 2rem;
        width: 100%; /* Full width of the viewport */
        position: relative; /* Ensure it's in the normal document flow */
    }

    .footer {
        height: 50px; /* Set the height of the footer */
        background-color: #09213A; /* Background color */
        color: white;
        font-size: 16px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
