@font-face {
    font-family: 'Barlow';
    src: url('../Fonts/Barlow/Barlow-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.solutions-page {
    height: auto; /* Adjust height to fit within the viewport */
    width: 100%; /* Full width */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: flex-start; /* Align items to the top */
    align-items: center; /* Center content horizontally */
    padding: 0; /* Remove any default padding */
    margin: 0; /* Remove any default margin */
}

.solutions-page h1 {
    font-family: 'Barlow', sans-serif;
    font-size: 58px;
    margin: 20px 0 0; /* Small margin on top to separate it from top edge */
    text-align: center;
}

.solutions-page p {
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    text-align: center;
    margin: 20px 0; /* Add space between h1 and p */
    width: 100%; /* Control the width for better alignment */
    padding-top: 1rem;
    padding-bottom: 5rem;
    padding-left: 4rem;
    padding-right: 4rem;
}

.content-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    padding: 0;
}

.text-section {
    flex: 1;
    color: white;
    height: 280px;
    padding: 20px;
    font-size: 28px;
    box-sizing: border-box;
    background-color: #00213B;
    max-width: 39%;
    border-top-right-radius: 4rem;
    border-bottom-right-radius: 4rem;
}

.text-section h2 {
    display: flex;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 40px;
    font-size: 30px;
    margin-top: 10px;
    max-width: 100%;
    text-align: center;
}

.sale-header{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00213B;
}

.sale-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    background-color: #00213B;
}

.sale-icon-container img {
    color: white;
    width: 50%;
}

.text-section p {
    margin-bottom: 3rem;
    font-size: 17px;
    text-align: left;
    padding-top: 0;
    padding-left: 1.8rem;
    padding-right: 5rem;
}

.image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.image-section img {
    max-width: 100%;
    height: 20rem;
    object-fit: contain;
}

.sale-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
}

.sale-text-section {
    flex: 1;
    padding: 20px 20px;
    color: white;
    background-color: #00213B;
    flex-direction: column;
    max-width: 50%;
    box-sizing: border-box;
    overflow: hidden;
    border-top-left-radius: 4rem;
    border-bottom-left-radius: 4rem;

}

.sale-text-section h2 {
    font-family: 'Barlow', sans-serif;
    display: flex;
    align-items: center;
    font-size: 35px;
    color: white;
    margin-left: 2rem;
}

.sale-text {
    font-family: 'Barlow', sans-serif;
    text-align: left;
    margin-top: 20px;
}

.sale-text p {
    text-align: left;
    font-size: 1rem;
    max-width: 95%;
    padding-top: 0;
    padding-left: 2rem;
}

.sale-image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    position: relative;
}

.Sale-image {
    max-width: 50%;
    transform: rotate(15deg);
    height: 35rem;
    position: relative;
    left: 0;
    margin-bottom: 5rem;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00213B;
    border-radius: 50%;
    padding-top: 1rem;
}

.icon-container img {
    width: 55%; /* Adjust to control image size inside the container */
    height: auto; /* Ensure it maintains aspect ratio */
    margin-bottom: 1.5rem;
    object-fit: contain;
    background-color: #00213B;
}

.software-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
}


.software-text-section {
    font-family: 'Barlow', sans-serif;
    flex: 1;
    padding: 20px 20px;
    color: white;
    background-color: #00213B;
    flex-direction: column;
    max-width: 39%;
    height: 300px;
    box-sizing: border-box;
    overflow: hidden;
    border-top-right-radius: 4rem;
    border-bottom-right-radius: 4rem;
}

.software-text-section h2 {
    display: flex;
    align-items: center;
    font-size: 35px;
    color: white;
    padding-top: 0;
}

.software-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00213B;
    border-radius: 50%;
    margin-top: 1rem;
}

.software-icon-container img {
    width: 50%; /* Adjust to control image size inside the container */
    height: auto; /* Ensure it maintains aspect ratio */
    margin-bottom: 1rem;
    object-fit: contain;
    background-color: #00213B;
}

.software-text p {
    text-align: left;
    font-size: 1rem;
    width: 100%;
    padding-top: 0;
    margin-left: -2rem;
}

.software-image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.software-image {
    max-width: 30%;
    height: auto;
    position: relative;
    top: 10px;
    margin-right: 80px;
    border-radius: 3rem;
}

.inventory-content-container {
    display: flex;
    background-color: #f8f8f8;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 10rem;
}
.inventory-text-section {
    font-family: 'Barlow', sans-serif;
    flex: 1;
    padding: 20px 20px;
    background-color: #00213B;
    color: white;
    flex-direction: column;
    max-width: 100%;
    height: 280px;
    max-height: 600px;
    box-sizing: border-box;
    overflow: hidden;
    border-top-left-radius: 4rem;
    border-bottom-left-radius: 4rem;
}

.inventory-text-section h2 {
    display: flex;
    align-items: center;
    font-size: 35px;
    color: white;
    margin: 0;
    margin-left: 50px;
}

.inventory-text {
    margin-top: 20px;
}

.inventory-text p {
    margin: 0;
    text-align: left;
    font-size: 1rem;
    padding-left: 3rem;
    padding-bottom: 0.9rem;
    padding-top: 0;
}

.inventory-image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    position: relative;
}

.inventory-image {
    max-width: 40%;
    height: auto;
    position: relative;
    top: 10px;
    margin-right: 20px;
    border-radius: 2.5rem;
}

.inventory-image2 {
    max-width: 50%;
    height: auto;
    position: relative;
    top: 10px;
    margin-right: 60px;
    border-radius: 1rem;
}

.reports-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 12rem;
}
.reports-text-section {
    font-family: 'Barlow', sans-serif;
    flex: 1;
    background-color: #00213B;
    padding-left: 2rem;
    padding-right: 2rem;
    color: white;
    flex-direction: column;
    max-width: 39%;
    height: 280px;
    box-sizing: border-box;
    overflow: hidden;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.reports-text-section h2 {
    display: flex;
    align-items: center;
    font-size: 35px;
    color: white;
    padding-left: 0;
    margin: 0;
    margin-left: -2rem;
}

.reports-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #00213B;
    border-radius: 50%;
    margin-right: 10px;
}

.reports-icon-container img {
    width: 60%; /* Adjust to control image size inside the container */
    height: auto; /* Ensure it maintains aspect ratio */
    margin-bottom: 1.5rem;
    object-fit: contain;
    background-color: #00213B;
}

.reports-text p {
    margin: 0;
    text-align: left;
    font-size: 1rem;
    width: 100%;
    padding: 0;
}

.reports-image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    position: relative;
}

.reports-image {
    max-width: 30%;
    height: auto;
    position: relative;
    margin-right: 20px;
    border-radius: 1rem;
}


.employee-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 10rem;
}


.employee-text-section {
    font-family: 'Barlow', sans-serif;
    flex: 1;
    color: white;
    background-color: #00213B;
    flex-direction: column;
    max-width: 100%;
    height: 280px;
    box-sizing: border-box;
    overflow: hidden;
    border-top-left-radius: 4rem;
    border-bottom-left-radius: 4rem;
}

.employee-text-section h2 {
    display: flex;
    align-items: center;
    font-size: 35px;
    color: white;
    margin: 0;
}

.employee-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4rem;
    background-color: #00213B;
    border-radius: 50%;
}


.employee-text p {
    margin: 0;
    text-align: left;
    font-size: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
}

.employee-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #00213B;
    border-radius: 50%;
}

.employee-icon-container img {
    width: 55%; /* Adjust to control image size inside the container */
    height: auto; /* Ensure it maintains aspect ratio */
    object-fit: contain;
    background-color: #00213B;
}

.employee-image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    position: relative;
}

.employee-image {
    max-width: 35%;
    height: auto;
    position: relative;
    top: 10px;
    margin-right: 80px;
    border-radius: 2.5rem;
}


@media screen and (max-width: 600px) {
    .solutions-page {
        width: 100%;
        padding: 0;
    }

    .solutions-page h1 {
        font-size: 3em;
        margin-top: 0;
        text-align: center;
    }

    .solutions-page p {
        font-size: 1.1em;
        margin-left: 0.625em;
        width: calc(100% - 1.25em);
        padding: 0;
        padding-bottom: 1rem;
    }

    .content-container {
        width: 100%;
        padding: 0;
        box-sizing: border-box;
        height: 12rem;
    }

    .solutions-page .text-section {
        flex: 1;
        color: white;
        background-color: #00213B;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 13rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        padding-left: 0;
    }

    .solutions-page .text-section h2 {
        margin-bottom: 1em;
        font-size: 16px;
        max-width: 100%;
        text-align: left;
        margin-top: 0;
    }

    .sale-header {
        font-size: 13px;
    }

    .sale-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85px;
        background-color: #00213B;
    }

    .sale-icon-container img {
        color: white;
        width: 50%;
    }

    .solutions-page .text-section p {
        font-size: 11px;
        margin-top: 1.2rem;
        margin-left: 12px;
        width: 100%;
    }
    .sale-content-container {
        padding-top: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0;
    }

    .sale-text-section {
        flex: 1;
        color: white;
        background-color: #00213B;
        flex-direction: column;
        overflow: hidden;
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
    }

    .sale-text-section h2 {
        font-family: 'Barlow', sans-serif;
        display: flex;
        font-size: 14px;
        color: white;
        margin-top: 0;
        margin-left: 0.5rem;
    }
    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        background-color: #00213B;
    }

    .icon-container img {
        color: white;
        width: 60%;
    }

    .sale-text {
        font-family: 'Barlow', sans-serif;
        text-align: left;
        margin-top: 0;
    }

    .sale-text p {
        text-align: left;
        font-size: 11px;
        max-width: 100%;
        padding-left: 0;
    }

    .sale-image-section {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
        position: relative;
    }

    .Sale-image {
        max-width: 100%;
        transform: rotate(15deg);
        height: 12rem;
        position: relative;
        left: 0;
        margin-bottom: 0;
    }

    .sale-icon-container {
        margin-right: 0;
        height: 50px;
    }

    .sale-icon-container i {
        color: white;
        font-size: 20px;
        padding-left: 0;
        margin-top: 0;
    }

    .icon-container {
        margin-right: 0;
        height: 50px;
    }

    .icon-container i {
        color: white;
        font-size: 20px;
        padding-left: 0;
        margin-top: 0;
    }

    .software-content-container {
        padding-top: 3rem;
    }


    .software-text-section {
        max-width: 50%;
        height: 15rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        padding: 9px;
    }

    .software-text-section h2 {
        font-size: 14px;
        color: white;
        margin-right: 2rem;
        margin-left: -1rem;
    }

    .software-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00213B;
        border-radius: 50%;
    }

    .software-icon-container img {
        width: 55%; /* Adjust to control image size inside the container */
        height: auto; /* Ensure it maintains aspect ratio */
        margin-bottom: 1rem;
        object-fit: contain;
        background-color: #00213B;
    }

    .software-text p {
        margin: 0;
        text-align: left;
        font-size: 11px;
        padding: 0;
        width: 100%;
        margin-left: 0.2rem;
    }

    .software-image-section {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
    }

    .software-image {
        max-width: 45%;
        height: auto;
        position: relative;
        margin-right: 5px;
        border-radius: 1rem;
    }

    .inventory-content-container {
        padding-top: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0;
    }
    .inventory-text-section {
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
        height: 200px;
    }

    .inventory-text-section h2 {
        font-family: 'Barlow', sans-serif;
        display: flex;
        font-size: 16px;
        color: white;
        margin-top: 0;
        margin-left: 0;
    }

    .inventory-text {
        margin-top: 10px;
    }

    .inventory-text p {
        text-align: left;
        font-size: 11px;
        max-width: 100%;
        margin-left: 0;
    }

    .inventory-image-section {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
        position: relative;
    }

    .inventory-image {
        max-width: 70%;
        height: auto;
        position: relative;
        top: 0;
        margin-right: 20px;
        border-radius: 1rem;
    }

    .inventory-image2 {
        max-width: 50%;
        height: auto;
        position: relative;
        top: 10px;
        margin-right: 60px;
        border-radius: 1rem;
    }

    .reports-content-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        margin: 0;
        padding-top: 3rem;
        height: 32rem;
    }

    .reports-text-section {
        font-family: 'Barlow', sans-serif;
        flex: 1;
        background-color: #00213B;
        padding-left: 10px;
        padding-right: 10px;
        color: white;
        flex-direction: column;
        max-width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .reports-text-section h2 {
        display: flex;
        align-items: center;
        font-size: 30px;
        color: white;
        margin: 0;
        margin-left: -1rem;
        margin-top: -1.3rem;
    }

    .reports-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        background-color: #00213B;
        padding: 0;
        margin-right: -2rem;
    }

    .reports-icon-container i {
        color: white;
        width: 50%;
    }

    .reports-text p {
        text-align: left;
        font-size: 13px;
        width: 100%;
        margin-top: -1rem;
    }

    .reports-image-section {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
        position: relative;
    }

    .reports-image {
        max-width: 34%;
        height: auto;
        position: relative;
        margin-right: 2px;
        border-radius: 0.5rem;
    }

    .employee-content-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        padding-top: 3rem;
    }

    .employee-text-section {
        font-family: 'Barlow', sans-serif;
        flex: 1;
        color: white;
        background-color: #00213B;
        flex-direction: column;
        max-width: 100%;
        height: 250px;
        box-sizing: border-box;
        overflow: hidden;
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
    }

    .employee-text-section h2 {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: white;
        margin: 0;
    }
    .employee-header {
        margin-left: 0.5rem;
    }

    .employee-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00213B;
        border-radius: 50%;
        height: 60px;
    }

    .employee-text p {
        margin: 0;
        text-align: left;
        font-size: 11px;
        width: 100%;
        padding-left: 0.5rem;
    }

    .employee-image-section {
        padding: 0;
    }

    .employee-image {
        max-width: 60%;
        height: auto;
        position: relative;
        top: 0;
        margin-right: 0;
        border-radius: 0.8rem;
    }



}
